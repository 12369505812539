<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.items.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                    
                </v-flex>
            </v-layout>
            <form @submit.prevent="addItems" autocomplete="off">
                <v-layout row wrap>
                    
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="items.item_title_ku" type="text"
                            :label="$store.getters.language.data.items.item_title_ku" dense class="mx-1" filled outlined
                            required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="items.item_title_en" type="text"
                            :label="$store.getters.language.data.items.item_title_en" dense class="mx-1" filled outlined
                            required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="items.item_title_ar" type="text"
                            :label="$store.getters.language.data.items.item_title_ar" dense class="mx-1" filled outlined
                            required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea rows="3" auto-grow v-model="items.item_text_ku" type="textarea"
                            :label="$store.getters.language.data.items.item_text_ku" dense class="mx-1" filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea rows="3" auto-grow v-model="items.item_text_ar" type="textarea"
                            :label="$store.getters.language.data.items.item_text_ar" dense class="mx-1" filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea rows="3" auto-grow v-model="items.item_text_en" type="textarea"
                            :label="$store.getters.language.data.items.item_text_en" dense class="mx-1" filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable :items="sections" v-model="items.section_id" dense filled
                            outlined item-text="section_name_en" item-value="section_id" :return-object="false"
                            label="Section">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable :items="['360','service','partner']" v-model="items.item_type" dense
                            filled outlined item-text="item_type" item-value="item_type" :return-object="false"
                            :label="$store.getters.language.data.items.item_type">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_url" type="textarea"
                            :label="$store.getters.language.data.items.item_url" dense class="mx-1" filled outlined>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_price" type="number"
                            :label="$store.getters.language.data.items.item_price" dense class="mx-1" filled outlined>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable :items="['active','deactive']" v-model="items.item_status"
                            dense filled outlined item-text="item_status" item-value="item_status"
                            :return-object="false" :label="$store.getters.language.data.items.item_status">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_index" type="number"
                            :label="$store.getters.language.data.items.item_index" dense class="mx-1" filled outlined
                            required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" class="mx-1" :loading="loading_btn" type="submit">
                            {{$store.getters.language.data.items.add_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
           
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/sections.request'
    import itemRequests from './../../requests/items.request'

    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                items: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                sections: [],
                
            }
        },
        computed: {
            page() {
                const pageKey = 'ITEMS'
                return {}
            }
        },
        mounted() {
            this.readItems();
        },
        methods: {
           
            addItems() {
                this.loading_btn = true
                itemRequests.createItems(this.items).then(r => {
                        if (r.status == 200 && r.data.status == 'ok') {
                            this.items = {}

                            this.snackbar = {
                                value: true,
                                text: 'Items Added',
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to add Items',
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })

            },
           

            readItems() {
                this.loading = true
                requests.getAllSections().then(r => {
                        if (r.status == 200) {
                            this.sections = r.data.sections
                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Items',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Items',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
           
        },
    }
</script>